import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { apiGetAcknowledgment, apiSubmitAcknowledgment } from "../api";
import { formatDateTime } from "../utils";
import SelectYear from "./SelectYear";

export default function HomePage(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [isStoring, setIsStoring] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [year, setYear] = useState<number>(0);

  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [comments, setComments] = useState("");
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const load = useCallback(() => {
    if (!year) return;

    setIsLoading(true);
    apiGetAcknowledgment(year)
      .then((data) => {
        setIsLoading(false);
        setErrorMessage("");

        setCode(data.code || "");
        setName(data.name || "");
        setEmail(data.email || "");
        setDate(data.date ? formatDateTime(data.date) : "");
        setComments(data.comments || "");
        setSubmitEnabled(data.canSubmit);
        setConfirmed(data.isAcknowledged);
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(e.message);

        setCode("");
        setName("");
        setEmail("");
        setDate("");
        setComments("");
        setSubmitEnabled(false);
        setConfirmed(false);
      });
  }, [year]);

  useEffect(() => load(), [load]);

  const submit = () => {
    setIsStoring(true);
    apiSubmitAcknowledgment(year, comments)
      .then(() => {
        setIsStoring(false);
        load();
      })
      .catch((e) => {
        setIsStoring(false);
        setErrorMessage(e.message);
      });
  };

  return (
    <Container maxWidth="md">
      <Backdrop open={isLoading || isStoring} style={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>
      <Box marginBottom={2} textAlign="right">
        <SelectYear year={year} onChange={(v) => setYear(v || 0)} />
      </Box>
      <Paper elevation={3}>
        <Box p={3}>
          {errorMessage && (
            <Box marginBottom={4}>
              <Alert severity="error">{errorMessage}</Alert>
            </Box>
          )}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                label="Employee No."
                value={code}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                label="Employee Name"
                value={name}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                label="E-mail"
                value={email}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                label="Date"
                value={date}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                minRows={6}
                label="Comments (if any, limit to 1000 characters)"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                disabled={!submitEnabled}
                inputProps={{ maxLength: 1000 }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Alert severity="info" icon={false}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="confirmBox"
                      disabled={!submitEnabled}
                      checked={confirmed}
                      onChange={(e) => setConfirmed(e.target.checked)}
                    />
                  }
                  label="By checking this box and submitting the acknowledgment the employee confirms that the review  was discussed with management and does not imply that the employee either agrees or disagrees with the employee assessment."
                />
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={!submitEnabled || !confirmed}
                variant="contained"
                color="primary"
                onClick={submit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}
