import { FormControl, MenuItem, Select } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { apiGetYears } from "../api";

interface SelectYearProps {
  fullWidth?: boolean;
  variant?: "standard" | "outlined" | "filled";
  emptyOptionLabel?: string;
  year?: number;
  onChange: (year: number | undefined) => void;
}

export default function SelectYear(props: SelectYearProps): ReactElement {
  const [years, setYears] = useState<number[]>([]);

  useEffect(() => {
    apiGetYears()
      .then((data) => {
        setYears(data);

        if (!props.emptyOptionLabel && data.length > 0) {
          props.onChange(data[0]);
        }
      })
      .catch((e) => {
        setYears([]);
        console.error(e.message);
      });

    // eslint-disable-next-line
  }, []);

  return (
    <FormControl
      size="small"
      variant={props.variant}
      fullWidth={props.fullWidth}
    >
      <Select
        value={props.year || "ALL"}
        onChange={(e) =>
          props.onChange(
            e.target.value !== "ALL" ? (e.target.value as number) : undefined,
          )
        }
      >
        {props.emptyOptionLabel && (
          <MenuItem value="ALL">{props.emptyOptionLabel}</MenuItem>
        )}
        {years.map((year) => (
          <MenuItem value={year}>{year}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
