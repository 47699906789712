import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { ReactElement } from "react";
import { Employee } from "../types";

interface EmployeeDialogProps {
  open: boolean;
  data: Employee;
  onDismiss: () => void;
}

export default function EmployeeDialog(
  props: EmployeeDialogProps,
): ReactElement {
  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={props.open}
      onClose={props.onDismiss}
    >
      <DialogTitle>Employee Acknowledgment</DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Employee No."
              value={props.data.code}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Employee Name"
              value={props.data.name}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="E-mail"
              value={props.data.email}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Date"
              value={props.data.date}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              minRows={6}
              label="Employee Comments"
              value={props.data.employeeComments}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              minRows={6}
              label="Manager Comments"
              value={props.data.managerComments}
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={props.onDismiss}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
