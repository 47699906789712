import { useSearchParams } from "react-router-dom";

export function useSearchParam<T>(
  name: string,
  defaultValue: T,
): [T, (x: T) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = (searchParams.get(name) as T) || defaultValue;

  const setParam = (value: T) =>
    setSearchParams(
      (params) => {
        if (value) {
          params.set(name, String(value));
        } else {
          params.delete(name);
        }
        return params;
      },
      { replace: true },
    );

  return [value, (value: T) => setParam(value)];
}
