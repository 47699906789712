import { getAccessToken } from "./auth";
import {
  Acknowledgment,
  CurrentUser,
  Employee,
  EmployeeOrder,
  NotificationInfo,
} from "./types";

const ApiPrefix: string = process.env.REACT_APP_API_URL_PREFIX || "";

type Params = Record<string, string>;

function formUrl(path: string, params?: Params): string {
  const url: URL = new URL(path, ApiPrefix);
  for (const name in params) {
    url.searchParams.append(name, params[name]);
  }
  return url.toString();
}

interface ApiCallParams {
  method: string;
  path: string;
  body?: object;
  params?: Params;
}

async function apiCallInner(params: ApiCallParams): Promise<Response> {
  const url: string = formUrl(params.path, params.params);
  const accessToken: string = await getAccessToken();
  const response: Response = await fetch(url, {
    method: params.method,
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params.body),
  });
  return response;
}

export async function apiGetCurrentUser(): Promise<CurrentUser> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "currentuser",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: CurrentUser = await response.json();
  return data;
}

export async function apiGetAcknowledgment(
  year: number,
): Promise<Acknowledgment> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "acknowledgment",
    params: { year: year.toString() },
  });
  if (response.status === 400) {
    throw Error("You are not eligible in the selected year.");
  } else if (!response.ok) {
    throw Error(response.statusText);
  }
  const data: Acknowledgment = await response.json();
  return data;
}

export async function apiSubmitAcknowledgment(
  year: number,
  comment: string,
): Promise<void> {
  const response: Response = await apiCallInner({
    method: "PUT",
    path: "acknowledgment",
    body: { year, comment },
  });
  if (response.status === 400) throw Error(await response.text());
  else if (!response.ok) throw Error(response.statusText);
}

export async function apiGetYears(): Promise<number[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "employee/years",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: number[] = await response.json();
  return data;
}

export async function apiGetEmployeeCount(
  year: number | undefined,
  codeContains: string | undefined,
  nameContains: string | undefined,
  emailContains: string | undefined,
  divisionContains: string | undefined,
): Promise<number> {
  const params: Params = {};
  if (year) {
    params["year"] = year.toString();
  }
  if (codeContains) {
    params["codeContains"] = codeContains;
  }
  if (nameContains) {
    params["nameContains"] = nameContains;
  }
  if (emailContains) {
    params["emailContains"] = emailContains;
  }
  if (divisionContains) {
    params["divisionContains"] = divisionContains;
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "employee/count",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: string = await response.text();
  return parseInt(data, 10);
}

export async function apiGetEmployees(
  year: number | undefined,
  codeContains: string | undefined,
  nameContains: string | undefined,
  emailContains: string | undefined,
  divisionContains: string | undefined,
  order: EmployeeOrder,
  skip: number,
  take: number,
): Promise<Employee[]> {
  const params: Params = {};
  if (year) {
    params["year"] = year.toString();
  }
  if (codeContains) {
    params["codeContains"] = codeContains;
  }
  if (nameContains) {
    params["nameContains"] = nameContains;
  }
  if (emailContains) {
    params["emailContains"] = emailContains;
  }
  if (divisionContains) {
    params["divisionContains"] = divisionContains;
  }
  params["order"] = order;
  params["skip"] = skip.toString();
  params["take"] = take.toString();
  const response: Response = await apiCallInner({
    method: "GET",
    path: "employee",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Employee[] = await response.json();
  return data;
}

export async function apiExportEmployees(
  year: number | undefined,
  codeContains: string | undefined,
  nameContains: string | undefined,
  emailContains: string | undefined,
  divisionContains: string | undefined,
  order: EmployeeOrder,
): Promise<Blob> {
  const params: Params = {};
  if (year) {
    params["year"] = year.toString();
  }
  if (codeContains) {
    params["codeContains"] = codeContains;
  }
  if (nameContains) {
    params["nameContains"] = nameContains;
  }
  if (emailContains) {
    params["emailContains"] = emailContains;
  }
  if (divisionContains) {
    params["divisionContains"] = divisionContains;
  }
  params["order"] = order;
  const response: Response = await apiCallInner({
    method: "GET",
    path: "employee/excel",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const blob: Blob = await response.blob();
  return blob;
}

export async function apiGetNotificationInfos(): Promise<NotificationInfo[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "notificationinfo/list",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: NotificationInfo[] = await response.json();
  return data;
}

export async function apiSaveNotificationInfo(
  divisionCode: string,
  sendEmailsFrom: string,
): Promise<void> {
  const response: Response = await apiCallInner({
    method: "POST",
    path: "notificationinfo/save",
    body: { divisionCode, sendEmailsFrom },
  });
  if (!response.ok) throw Error(response.statusText);
}

export async function apiDeleteNotificationInfo(
  divisionCode: string,
): Promise<void> {
  const response: Response = await apiCallInner({
    method: "DELETE",
    path: "notificationinfo/delete",
    body: { divisionCode },
  });
  if (!response.ok) throw Error(await response.text());
}

export async function apiSendRemindersForDivision(
  divisionCode: string,
): Promise<void> {
  const response: Response = await apiCallInner({
    method: "POST",
    path: "sendmail/remindersfordivision",
    params: { divisionCode },
  });
  if (!response.ok) throw Error(response.statusText);
}
