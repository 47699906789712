export interface Acknowledgment {
  code?: string;
  name?: string;
  email?: string;
  date?: string;
  comments?: string;
  canSubmit: boolean;
  isAcknowledged: boolean;
}

export interface CurrentUser {
  email: string;
  isManager: boolean;
}

export enum EmployeeOrder {
  YEAR_ASC = "YEAR_ASC",
  YEAR_DESC = "YEAR_DESC",
  CODE_ASC = "CODE_ASC",
  CODE_DESC = "CODE_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  EMAIL_ASC = "EMAIL_ASC",
  EMAIL_DESC = "EMAIL_DESC",
  DIVISION_ASC = "DIVISION_ASC",
  DIVISION_DESC = "DIVISION_DESC",
  DATE_ASC = "DATE_ASC",
  DATE_DESC = "DATE_DESC",
}

export interface Employee {
  year: number;
  code: string;
  name: string;
  email: string;
  date: string;
  division: string;
  managerComments: string;
  employeeComments: string;
}

export const EmptyEmployee: Employee = {
  year: 0,
  code: "",
  name: "",
  email: "",
  date: "",
  division: "",
  managerComments: "",
  employeeComments: "",
};

export interface NotificationInfo {
  divisionCode: string;
  divisionName: string;
  sendEmailsFrom: string;
  sendEmailsTo: string;
  completionDate: string;
  updatedBy: string;
  updatedAt: string;
  canUpdate: boolean;
}
