export function dateToISODate(date: string): string {
  if (date) {
    return new Date(date).toISOString().split("T")[0];
  }
  return "";
}

export function formatDateOnly(date: string): string {
  if (date) {
    return new Date(date).toLocaleDateString();
  }
  return "-";
}

export function formatDateTime(date: string): string {
  if (date) {
    return new Date(date).toLocaleString();
  }
  return "-";
}

export function saveBlob(blob: Blob, filename: string): void {
  const a: HTMLAnchorElement = document.createElement("a");
  a.download = filename;
  a.href = window.URL.createObjectURL(blob);
  a.dispatchEvent(
    new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    }),
  );
  a.remove();
}
