import GetAppIcon from "@mui/icons-material/GetApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import {
  apiExportEmployees,
  apiGetEmployeeCount,
  apiGetEmployees,
} from "../api";
import { useSearchParam } from "../hooks";
import { Employee, EmployeeOrder, EmptyEmployee } from "../types";
import { formatDateTime, saveBlob } from "../utils";
import EmployeeDialog from "./EmployeeDialog";
import SelectYear from "./SelectYear";
import TableFilterCell from "./TableFilterCell";
import TableHeaderCell from "./TableHeaderCell";

export default function EmployeesPage(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [year, setYear] = useSearchParam("year", 0);
  const [codeFilter, setCodeFilter] = useSearchParam("code", "");
  const [nameFilter, setNameFilter] = useSearchParam("name", "");
  const [emailFilter, setEmailFilter] = useSearchParam("email", "");
  const [divisionFilter, setDivisionFilter] = useSearchParam("division", "");

  const [order, setOrder] = useSearchParam("order", EmployeeOrder.CODE_ASC);

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [count, setCount] = useState(0);

  const [page, setPage] = useSearchParam("page", 0);
  const [rowsPerPage, setRowsPerPage] = useSearchParam("rowsPerPage", 10);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] =
    useState<Employee>(EmptyEmployee);

  useEffect(() => {
    apiGetEmployeeCount(
      year,
      codeFilter,
      nameFilter,
      emailFilter,
      divisionFilter,
    )
      .then((count) => setCount(count))
      .catch((e) => setErrorMessage(e.message));
  }, [year, codeFilter, nameFilter, emailFilter, divisionFilter]);

  useEffect(() => {
    setIsLoading(true);
    apiGetEmployees(
      year,
      codeFilter,
      nameFilter,
      emailFilter,
      divisionFilter,
      order,
      page * rowsPerPage,
      rowsPerPage,
    )
      .then((data) => {
        setIsLoading(false);
        setEmployees(data);
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(e.message);
      });
  }, [
    year,
    codeFilter,
    nameFilter,
    emailFilter,
    divisionFilter,
    order,
    page,
    rowsPerPage,
  ]);

  const exportToExcel = () => {
    apiExportEmployees(
      year,
      codeFilter,
      nameFilter,
      emailFilter,
      divisionFilter,
      order,
    )
      .then((blob) => saveBlob(blob, "Employees.xlsx"))
      .catch((e) => setErrorMessage(e.message));
  };

  return (
    <Container maxWidth="xl">
      <Backdrop open={isLoading} style={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <EmployeeDialog
        open={isDialogOpen}
        data={selectedEmployee}
        onDismiss={() => setIsDialogOpen(false)}
      />
      <Box marginBottom={2}>
        <Button
          color="secondary"
          variant="contained"
          size="small"
          startIcon={<GetAppIcon />}
          onClick={exportToExcel}
        >
          Export to Excel
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" padding="normal">
          <TableHead>
            <TableRow>
              <TableHeaderCell
                title="Year"
                order={order}
                orderAsc={EmployeeOrder.YEAR_ASC}
                orderDesc={EmployeeOrder.YEAR_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Code"
                order={order}
                orderAsc={EmployeeOrder.CODE_ASC}
                orderDesc={EmployeeOrder.CODE_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Name"
                order={order}
                orderAsc={EmployeeOrder.NAME_ASC}
                orderDesc={EmployeeOrder.NAME_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="E-mail"
                order={order}
                orderAsc={EmployeeOrder.EMAIL_ASC}
                orderDesc={EmployeeOrder.EMAIL_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Division"
                order={order}
                orderAsc={EmployeeOrder.DIVISION_ASC}
                orderDesc={EmployeeOrder.DIVISION_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableHeaderCell
                title="Acknowledged Date"
                order={order}
                orderAsc={EmployeeOrder.DATE_ASC}
                orderDesc={EmployeeOrder.DATE_DESC}
                onOrderChange={(o) => setOrder(o)}
              />
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>
                <SelectYear
                  fullWidth
                  variant="standard"
                  emptyOptionLabel="All"
                  year={year}
                  onChange={(v) => setYear(v || 0)}
                />
              </TableCell>
              <TableFilterCell
                value={codeFilter}
                onChange={(v) => setCodeFilter(v)}
              />
              <TableFilterCell
                value={nameFilter}
                onChange={(v) => setNameFilter(v)}
              />
              <TableFilterCell
                value={emailFilter}
                onChange={(v) => setEmailFilter(v)}
              />
              <TableFilterCell
                value={divisionFilter}
                onChange={(v) => setDivisionFilter(v)}
              />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.map((row) => (
              <TableRow key={row.year + row.code}>
                <TableCell>{row.year}</TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.division}</TableCell>
                <TableCell>{formatDateTime(row.date)}</TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => {
                      setSelectedEmployee(row);
                      setIsDialogOpen(true);
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        showFirstButton={true}
        showLastButton={true}
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(_, p) => setPage(p)}
        onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
      />
    </Container>
  );
}
